html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
  color: #666666;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app > section {
  position: relative;
}

.container {
  position: relative;
}

a {
  color: #03c4eb;
  transition: 0.5s;
}

a:hover,
a:active,
a:focus {
  color: #03c5ec;
  outline: none;
  text-decoration: none;
}

p {
  padding: 0;
  margin: 0 0 3rem 0;
}

ul {
  padding: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  margin: 0 0 2rem 0;
  padding: 0;
}

.section-title {
  font-size: 2.2rem;
  color: #111;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  margin-top: 5rem;
}

.section-description {
  text-align: center;
  margin-bottom: 4rem;
}

.section-title-divider {
  width: 5rem;
  height: 0.3rem;
  background: #03c4eb;
  margin: 0 auto;
  margin-bottom: 2rem;
}

.bg-source {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.container {
  padding: 3rem;
}

.slide {
  opacity: 0;
  transform: translate3d(0, 100%, 0);
}

.slide-up {
  opacity: 1;
  transform: translate3d(0, 0%, 0);
  transition: all 1s ease;
}

.slide-down {
  opacity: 0;
  transform: translate3d(0, 100%, 0);
  transition: all 1s ease;
}

.delay-0 {
  transition-delay: 0s;
}

.delay-1 {
  transition-delay: 0.3s;
}

.delay-2 {
  transition-delay: 0.6s;
}

.delay-3 {
  transition-delay: 0.9s;
}

.delay-4 {
  transition-delay: 1.2s;
}

.delay-5 {
  transition-delay: 1.5s;
}

.delay-6 {
  transition-delay: 1.8s;
}

.delay-7 {
  transition-delay: 2.1s;
}

.delay-8 {
  transition-delay: 2.4s;
}

.delay-9 {
  transition-delay: 2.7s;
}

@media (max-width: 768px) {
  .delay-0 {
    transition-delay: 0.3s;
  }

  .delay-1 {
    transition-delay: 0.6s;
  }

  .delay-2 {
    transition-delay: 0.9s;
  }

  .delay-3 {
    transition-delay: 1.2s;
  }

  .delay-4 {
    transition-delay: 1.5s;
  }

  .delay-5 {
    transition-delay: 1.8s;
  }

  .delay-6 {
    transition-delay: 2.1s;
  }

  .delay-7 {
    transition-delay: 2.4s;
  }

  .delay-8 {
    transition-delay: 2.7s;
  }

  .delay-9 {
    transition-delay: 3s;
  }
}
