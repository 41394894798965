#contact {
  background: linear-gradient(rgba(158, 134, 0, 0.3), rgba(0, 0, 0, 0.6)),
    url(./bg.jpg) fixed top center;
  background-size: cover;
}
#contact .container {
  text-align: center;
}

#contact h3 {
  color: #fff;
  font-size: 1.8rem;
  font-weight: 700;
}

#contact .sns-list img {
  height: 3rem;
  margin: 0 0.5rem;
}

#contact .contact-link + .contact-link {
  padding-left: 1rem;
}

#contact .contact-link div {
  display: inline-block;
}
